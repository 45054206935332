const leagues = [
  {
    title: "Pony Leagues",
    isCollapsable: true,
    subItems: [
      {
        title: "EI90 (P)",
        route: "/ei90p",
      },
      {
        title: "EI100 (P)",
        route: "/ei100p",
      },
      {
        title: "EI110 (P)",
        route: "/ei110p",
      },
    ],
  },
  {
    title: "Junior Leagues",
    isCollapsable: true,
    subItems: [
      {
        title: "EI100 (J)",
        route: "/ei100j",
      },
      {
        title: "EI110 (J)",
        route: "/ei110j",
      },
    ],
  },
  {
    title: "Young Rider Leagues",
    isCollapsable: true,
    subItems: [
      {
        title: "EI110 Young Rider",
        route: "/ei_young_rider_110",
      },
      {
        title: "EI115 Young Rider",
        route: "/ei_young_rider_115",
      },
    ],
  },
  {
    title: "Amateur Leagues",
    isCollapsable: true,
    subItems: [
      { title: "Amateur EI90", route: "/ei_amateur_90", },
      { title: "Amateur EI100", route: "/ei_amateur_100", },
      { title: "Amateur EI110", route: "/ei_amateur_110", },
    ],
  },
  // { title: "Elo Ratings", route: "/elo" },
];
export default leagues;
