import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";

import { theme } from "../config";

import VStack from "./VStack";
import Spacer from "./Spacer";
import HStack from "./HStack";
import Text from "./Text";
import { Chevron } from "../assets";
import leagues from "../services/leagues";

const Container = styled.div`
  display: none;
  @media (${theme.breakpoints.mediumAndUp}) {
    display: flex;
    min-width: 200px;
    background-color: ${theme.colors.white100};
    border-right: 0.5px solid ${theme.colors.grey30};
    &:hover {
      color: ${theme.colors.white100};
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100vh;
  width: 60%;
  background-color: ${theme.colors.white100};
  z-index: 1000;
  border-right: 0.5px solid ${theme.colors.grey30};
  transition: transform 300ms ease-in-out;
  @media (${theme.breakpoints.mediumAndUp}) {
    transition: transform 300ms ease-in-out;
    display: none;
  }
`;
const SidebarItemContainer = styled.div`
  width: 100%;
  color: ${({ active }) =>
    active ? theme.colors.white100 : theme.colors.secondary100};
  background: ${({ active }) =>
    active ? theme.colors.secondary100 : theme.colors.white100};
  y100 &:hover {
    background: ${theme.colors.secondary100};
    color: ${theme.colors.white100};
  }
  cursor: pointer;
`;
const ActiveVStack = styled(VStack)`
  width: 100%;
  color: ${({ active }) =>
    active ? theme.colors.white100 : theme.colors.secondary100};
  background: ${({ active }) =>
    active ? theme.colors.secondary100 : theme.colors.white100};
  y100 &:hover {
    background: ${theme.colors.secondary100};
    color: ${theme.colors.white100};
  }
  cursor: pointer;
`;
const ItemText = styled.h1`
  font-size: ${theme.spacing.x5};
  font-weight: 600;
  padding-left: ${theme.spacing.x2};
`;
const SubItemText = styled(Text)`
  padding-left: ${theme.spacing.x4};
`;

const SidebarItem = ({ item, active, setActiveRoute, closeSidebar }) => {
  const [activeSubRoute, setActiveSubRoute] = useState(0);
  const [accordionOpen, setAccordionOpen] = useState(false);
  return !item.isCollapsable ? (
    <SidebarItemContainer
      active={active}
      onClick={() => {
        closeSidebar();
        setActiveRoute();
      }}
    >
      <HStack align="center" justify="space-between">
        <ItemText>{item.title}</ItemText>
      </HStack>
    </SidebarItemContainer>
  ) : (
    <SidebarItemContainer>
      <VStack>
        <HStack
          align="center"
          justify="space-around"
          onClick={() => {
            setAccordionOpen(!accordionOpen);
            localStorage.setItem("accordionOpen", true);
          }}
        >
          <ItemText>{item.title}</ItemText>
          <Chevron
            direction={accordionOpen ? "top" : "bottom"}
            fill={theme.colors.secondary100}
          />
        </HStack>
        {accordionOpen &&
          item.subItems.map((subItem, idx) => {
            return (
              <ActiveVStack
                align="flex-start"
                active={active && activeSubRoute === idx}
                onClick={async () => {
                  closeSidebar();
                  await localStorage.setItem("activeSubRoute", idx);
                  await setActiveSubRoute(idx);
                  setActiveRoute(subItem.route);
                }}
              >
                <Spacer height="x2" />
                <SubItemText
                  color={
                    active && activeSubRoute === idx
                      ? "white100"
                      : "secondary100"
                  }
                  size="x3"
                  weight="semibold"
                >
                  {subItem.title}
                </SubItemText>
                <Spacer height="x2" />
              </ActiveVStack>
            );
          })}
      </VStack>
    </SidebarItemContainer>
  );
};

const Sidebar = ({
  showSidebar,
  activeRoute,
  setActiveRoute,
  setShowSidebar,
}) => {
  const menuItems = [
    { title: "Dashboard", route: "/" },
    { title: "All-time Stats", route: "/alltime" },
    { title: "Records", route: "/records" },
    { title: "Red Mills League", route: "/redmills" },
    { title: "Treo Eile", route: "/ei_treo_eile" },
    { title: "TIH Eventing League", route: "/ei_tih" },
  ];

  if (showSidebar) {
    return (
      <MobileContainer>
        <VStack>
          {menuItems.concat(leagues).map((item, idx) => {
            return (
              <SidebarItem
                key={idx}
                item={item}
                closeSidebar={() => setShowSidebar(false)}
                setActiveRoute={async (subRoute) => {
                  if (item.isCollapsable) {
                    await localStorage.setItem("accordionOpen", idx);
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(subRoute);
                  } else {
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(item.route);
                  }
                }}
                active={activeRoute === idx}
              />
            );
          })}
        </VStack>
      </MobileContainer>
    );
  } else {
    return (
      <Container>
        <VStack>
          {menuItems.concat(leagues).map((item, idx) => {
            return (
              <SidebarItem
                key={idx}
                item={item}
                closeSidebar={() => { }}
                setActiveRoute={async (subRoute) => {
                  if (item.isCollapsable) {
                    await localStorage.setItem("accordionOpen", idx);
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(subRoute);
                  } else {
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(item.route);
                  }
                }}
                active={activeRoute === idx}
              />
            );
          })}
        </VStack>
      </Container>
    );
  }
};

export default Sidebar;
